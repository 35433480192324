#no-search-results {
	font-family: "Roboto";
	font-weight: 400;
	text-align: center;
}

.react-sanfona {
	width: 700px;
	border-radius: 4px;
	box-shadow: 2px 2px 20px #d8d8d8;
	margin: auto;
}

@media screen and (max-width: 700px) {
	.react-sanfona {
		width: 90vw;
	}
}

.react-sanfona-item-title {
	padding: 15px;
	background-color: #f9f9f9;
	font-family: "Roboto";
	font-size: 20px;
	font-weight: 400;
	color: #333;
	text-transform: capitalize;

	transition-duration: 200ms;
}

.react-sanfona-item-title:hover {
	background-color: #e9e9e9;
}

.react-sanfona-item-expanded .react-sanfona-item-title {
	background-color: #fa6c62;
	color: white;
}

.react-sanfona-item-title::after {
	float: right;
	content: "+";
	font-size: 24px;
	font-weight: 400;

	transition-duration: 300ms;
}

.react-sanfona-item-expanded .react-sanfona-item-title::after {
	transform: rotate(45deg);
}

.react-sanfona-item-body-wrapper {
	border-bottom: 1px solid #a9a9a9;
	border-radius: 4px;
	z-index: -999;
}

.react-sanfona-element {
	padding: 15px 25px;
	background-color: white;
	font-family: "Roboto";
	font-weight: 300;
	color: #333;
}

.react-sanfona-element:hover {
	background-color: #f5f5f5;
}

.fa-gear {
	float: right;
	font-size: 25px;
	color: #414141;

	transition-duration: 300ms;
}

.fa-gear:hover {
	cursor: pointer;

	transform: rotate(80deg);
}

/* add app button */

.add-app {
	border-radius: 4px;
	background-color: lightblue;
	border: none;
	outline: none;
	color: #444;
	text-align: center;
	padding: 10px;
	width: 200px;
	height: 45px;
	transition: all 0.5s;
	cursor: pointer;
}

.add-app span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 15px;
	font-weight: 600;
	transition: 0.5s;
}

.add-app span:after {
	content: "+";
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}

.add-app:hover span {
	padding-right: 15px;
}

.add-app:hover span:after {
	opacity: 1;
	right: 0;
}

.add-application-container {
	width: 550px;

	padding: 50px;
	border: 1px solid #ccc;
	border-radius: 10px;
	background-color: #f9f9f9;
	box-shadow: 5px 5px 50px #afafaf;
	overflow-y: auto;
}
.add-application-container .loader-listing {
	display: flex;
	justify-content: center;
	margin: auto;
	align-items: center;
	padding-top: 150px;
	padding-bottom: 150px;
}
.search-input {
	width: 400px;
	height: 45px;
	margin-bottom: 15px;
	border: 1px solid #bebebe;
	outline: none;
	background-color: white;
	padding-left: 10px;
	font-size: 16px;
	border-radius: 3px;
	margin: auto;
}
.search-input:hover {
	border: 1px solid #888888;
}
.tenant-footer {
	justify-content: space-around;
	display: flex;
}
.tenant-footer .custom-button {
	width: 45% !important;
}

@media screen and (max-width: 600px) {
	.add-application-container {
		width: 90%;
		max-height: 60vh;
		margin: 0 auto;
		padding: 20px;
		border: none;
		background-color: #f9f9f9;
	}
}

/* add app */

.tenant-name-heading {
	margin-top: 20px;
	font-family: "Roboto";
	font-size: 20px;
	font-weight: 500;
	color: grey;
	letter-spacing: 1px;
	text-transform: capitalize;
}

/* tenant info */

.fa-info-circle {
	float: right;
	font-size: 25px;
	color: #696969;
	line-height: 40px;
	vertical-align: middle;
	cursor: pointer;

	transition-duration: 300ms;
}

.fa-info-circle:hover {
	color: #333;
}

.tenant-info-title {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 30px;
	color: #fa6c62;
}

#tenant-info-table-container {
	padding: 50px;
	border: 1px solid #ccc;
	border-radius: 10px;
	background-color: #f9f9f9;
	overflow-y: auto;
	height: 300px;
}

.addUser {
	font-size: 12px;
	color: #fff;
	text-align: center;
	line-height: 20px;
	background: #30bced;
	padding: 3px 10px;
	border-radius: 20px;
	border: none;
}
.margin-top-100 {
	margin-top: 100px;
}
.margin-top-100 .loader-listing {
	padding-top: 60px !important;
}
@media screen and (max-width: 600px) {
	#tenant-info-table-container {
		width: 90vw;
		max-height: 70vh;
		margin: 0 auto;
		padding: 20px;
		border: none;
		background-color: #f9f9f9;
	}
}

#tenant-info-table tbody {
	width: 100%;
}

#tenant-info-table {
	font-family: "Roboto";
	font-weight: 300;
	border-collapse: collapse;
	border-radius: 10px;
	width: 100%;
}

#tenant-info-table tr {
	width: 100%;
}

#tenant-info-table td,
#tenant-info-table th {
	/* border: 1px solid #ddd; */
	padding: 10px 30px;
}

@media screen and (max-width: 600px) {
	#tenant-info-table td,
	#tenant-info-table th {
		padding: 10px 5px;
	}
}

#tenant-info-table tr:nth-child(odd) {
	background-color: #f2f2f2;
}

#tenant-info-table tr:hover {
	background-color: #ddd;
}

.tenant-card-list-container {
	display: flex;
	flex-wrap: wrap;
	margin: 40px 70px;
	justify-content: center;
}

.modal {
	font-size: 12px;
}
.modal > .header {
	width: 100%;
	border-bottom: 1px solid gray;
	font-size: 18px;
	text-align: center;
	padding: 5px;
}
.modal > .content {
	width: 100%;
	padding: 10px 5px;
}
.modal > .actions {
	width: 100%;
	padding: 10px 5px;
	margin: auto;
	text-align: center;
}
.modal > .close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: #ffffff;
	border-radius: 18px;
	border: 1px solid #cfcece;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: #f8f8f8;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 5px;
	background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
	background: #9c9c9c;
	border-radius: 5px;
}

.features-flag {
	font-family: "Roboto";
	font-size: 20px;
	font-weight: 100;
}

.tenant-key-container .key-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #dcdcdc;
	padding: 10px;
	margin-bottom: 10px;
	background-color: aliceblue;
}

.tenant-key-container .key-row > span {
	font-family: "Roboto";
}

.freshworks-id-row > form {
	display:flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 20px;
	margin-bottom:20px;
}

.freshworks-label-sync {
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 20px;
}

.freshworks-sync-button {
	cursor: pointer;
}

.freshworks-id-input > input {
 margin:0px;
}

.key-title {
	color: #9c9c9c;
}
